import { useHistory, useLocation } from 'react-router'

export const useQueryParams = () => {
  const query = new URLSearchParams(useLocation().search)
  const getParam = (name: string) => query.get(name) || ''

  return getParam
}

export const useQueryParams2 = () => {
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)

  const getParam = (name: string) => query.get(name)

  const setParams = (params: Record<string, string>) => {
    const query = new URLSearchParams(location.search)

    Object.entries(params).forEach(([key, value]) => {
      query.set(key, value)
    })

    history.replace({ search: query.toString() })
  }

  const setParam = (name: string, value: string) => {
    query.set(name, value)
    history.replace({ search: query.toString() })
  }

  return {
    getParam,
    setParam,
    setParams,
  }
}
